import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages";
import Banner from "./pages/banner";
import Boneyard from "./pages/boneyard";
import Header from "./components/Header";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Skullies GMI</title>
        <meta name="description" content="Skullies GMI" />
      </Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="banner" element={<Banner />} />
        <Route path="boneyard" element={<Boneyard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
