import { useState } from "react";
import HeaderLink from "./HeaderLink";
const logo = require("../assets/skullieslogo.png");

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <header className="fixed top-0 bg-black w-full flex justify-between sm:justify-around items-center h-[80px] shadow-lg z-50 border-b-2 border-accentalt">
      <div className="relative h-12 z-20 ml-6 md:ml-2">
        <img src={logo} width={120} />
      </div>

      <div className="flex items-center">
        <div className="hidden sm:flex space-x-8 pr-4">
          <HeaderLink label="Wallpaper" href="/" />
          <HeaderLink label="Banner" href="/banner" />
          <HeaderLink label="Boneyard" href="/boneyard" />
        </div>
        <button
          className={`menu ${menuOpen} z-20 sm:hidden`}
          onClick={toggleMenu}
        >
          <div className="">
            <span className="top"></span>
            <span className="bottom"></span>
          </div>
        </button>
      </div>
      <nav
        className={`bg-black flex-col flex mt-[72px] pb-[216px] h-screen w-screen fixed top-0 center space-y-7 sm:hidden transition-all translate-x-full ${
          menuOpen && "translate-x-0"
        }`}
      >
        <HeaderLink label="Wallpaper" href="/" large onClick={toggleMenu} />
        <HeaderLink label="Banner" href="/banner" large onClick={toggleMenu} />
        <HeaderLink
          label="Boneyard"
          href="/boneyard"
          large
          onClick={toggleMenu}
        />
      </nav>
    </header>
  );
};

export default Header;
