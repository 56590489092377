import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { FaDice } from "react-icons/fa";
import { fetchEnsName } from "../utils";

const bannerText = require("../assets/banner_text.png");
const dice = require("../assets/dice.gif");
const banner1 = require("../assets/banner_1.png");
const banner2 = require("../assets/banner_2.png");
const banner3 = require("../assets/banner_3.png");
const banner4 = require("../assets/banner_4.png");
const banner5 = require("../assets/banner_5.png");
const banner6 = require("../assets/banner_6.png");
const banner7 = require("../assets/banner_7.png");
const banner8 = require("../assets/banner_8.png");
const banner9 = require("../assets/banner_9.png");
const banner10 = require("../assets/banner_10.png");
const banner11 = require("../assets/banner_11.png");
const bgs = [
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  banner9,
  banner10,
  banner11,
];

const border1 = require("../assets/banner_border_1.png");
const border2 = require("../assets/banner_border_2.png");
const border3 = require("../assets/banner_border_3.png");
const border4 = require("../assets/banner_border_4.png");
const border5 = require("../assets/banner_border_5.png");
const border6 = require("../assets/banner_border_6.png");
const border7 = require("../assets/banner_border_7.png");
const border9 = require("../assets/banner_border_9.png");
const border10 = require("../assets/banner_border_10.png");
const borders = [
  border1,
  border2,
  border3,
  border4,
  border5,
  border6,
  border7,
  border9,
  border10,
];
const black = require("../assets/banner_border_9.png");
const fire = require("../assets/fire.gif");
const genesis = require("../assets/genesis.gif");

function Index() {
  const [image, setImage] = useState(null);
  const [item, setItem] = useState(null);
  const [owner, setOwner] = useState("Loading...");
  const [error, setError] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rand, setRand] = useState(Math.floor(Math.random() * bgs.length));
  const [rand2, setRand2] = useState(
    Math.floor(Math.random() * borders.length)
  );
  const [randomizing, setRandomizing] = useState(false);

  const canvasRef = useRef(null);

  useEffect(() => {
    if (generate) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.height = 500;
      canvas.width = 1500;

      //BG Image
      let bg = new Image();
      bg.src = bgs[rand];

      bg.onload = () => {
        context.drawImage(
          bg,
          0,
          0,
          canvas.height / (bg.height / bg.width),
          canvas.height
        );

        //Frame Skull
        let border = new Image();
        border.src = borders[rand2];
        border.onload = () => {
          let borderWidth = 400;
          let borderHeight = 400;
          if (border.height > border.width) {
            borderHeight = 400 / (border.width / border.height);
          } else {
            borderWidth = 400 / (border.height / border.width);
          }
          context.drawImage(
            border,
            canvas.width / 2 - borderWidth / 2,
            canvas.height / 2 - borderHeight / 2,
            borderWidth,
            borderHeight
          );
          //Art
          let artImage = new Image();
          if (item === "fire") {
            artImage.src = fire;
          } else {
            artImage.src = genesis;
          }
          artImage.onload = () => {
            context.drawImage(
              artImage,
              canvas.width / 2 - 275 / 2,
              canvas.height / 2 - 275 / 2,
              275 / (artImage.height / artImage.width),
              275
            );
          };
          //Black
          let textBg = new Image();
          textBg.src = black;
          textBg.onload = () => {
            context.drawImage(textBg, -30, -10, 580, 100);

            //Text
            context.fillStyle = "#ff0000";
            context.font = `60px 'TrashHand'`;
            context.fillText(`Boneyard Skull:`, 20, 55);
            context.fillStyle = "#00d4c8";

            context.fillText(item, 360, 55);
            context.font = `50px 'TrashHand'`;
            context.fillStyle = "#ff0000";
          };
        };
      };
    }
  }, [generate, rand, rand2]);

  const downloadImage = () => {
    const canvas = canvasRef.current;

    var url = canvas.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "SkulliesWallpaper.png";
    link.href = url;
    link.click();
  };

  const reset = () => {
    setImage(null);
    setGenerate(false);
    setLoading(false);
  };

  useEffect(() => {
    if (randomizing) {
      setTimeout(() => {
        setRand2(Math.floor(Math.random() * borders.length));
        setRand(Math.floor(Math.random() * bgs.length));
      }, 200);
    }
    return () => {
      setTimeout(() => {
        setRandomizing(false);
      }, 600);
    };
  }, [randomizing]);

  return (
    <>
      <Helmet>
        <title>Wallpaper | Boneyard</title>
        <meta
          name="description"
          content="Suit up your Twitter with a custom wallpaper of your Skully!"
        />
      </Helmet>
      <div className="bg-black min-h-screen flex flex-col pt-[120px] items-center px-3">
        <div className=" relative w-[300px] sm:w-[450px] lg:w-[600px] xl:w-[750px] mb-6">
          <img
            src={bannerText}
            alt="Twitter Banner"
            className="object-contain"
          />
        </div>
        {!generate ? (
          <form className="w-full max-w-lg bg-zinc-800 rounded-lg py-6 px-3 flex flex-col justify-center shadow-md">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-accentaltdark text-xs font-bold mb-2">
                Boneyard Skull
              </label>
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Select a Team"
                  value={item}
                  onChange={(e) => {
                    setItem(e.target.value);
                  }}
                >
                  <option value="">Choose your Boneyard Skull</option>
                  <option value="fire">Fire Skull</option>
                  <option value="genesis">Genesis Skull</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {error && (
                <p className="text-red-500 text-xs italic mt-2">{error}</p>
              )}
            </div>

            <div>
              <button
                onClick={() => setGenerate(true)}
                className="font-trash text-[1.2em] tracking-wider bg-accent hover:opacity-50 text-dark py-2 px-4 ml-3 mt-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
              >
                {loading ? "Loading" : "Generate"}
              </button>
            </div>
          </form>
        ) : (
          <div className="flex flex-col justify-center items-center w-full ">
            <div
              className="flex items-center justify-center scale-50 "
              style={{
                fontFamily: "trash",
              }}
            >
              <div className="flex mt-[-200px] p-2">
                <canvas ref={canvasRef} className=""></canvas>
              </div>
            </div>
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded flex items-center mx-auto"
              onClick={() => {
                setRandomizing(true);
              }}
            >
              <FaDice className="mr-2" />
              <span className="font-trash">Randomize</span>
            </button>
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded flex items-center mx-auto"
              onClick={downloadImage}
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
              </svg>
              <span className="font-trash">Download</span>
            </button>
            <p
              className="text-red-600 font-mono underline mt-3 mb-6 cursor-pointer"
              onClick={reset}
            >
              reset
            </p>
          </div>
        )}
      </div>
      <div
        className="fixed flex top-0 left-0 h-screen w-screen bg-black center z-50 transition-opacity"
        style={{
          pointerEvents: "none",
          opacity: randomizing ? "1" : "0",
        }}
      >
        <img src={dice} className="w-[300px] md:w-[400px]" />
      </div>
    </>
  );
}

export default Index;
