import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { FaDice } from "react-icons/fa";
import { fetchEnsName } from "../utils";

const bannerText = require("../assets/banner_text.png");
const dice = require("../assets/dice.gif");
const banner1 = require("../assets/banner_1.png");
const banner2 = require("../assets/banner_2.png");
const banner3 = require("../assets/banner_3.png");
const banner4 = require("../assets/banner_4.png");
const banner5 = require("../assets/banner_5.png");
const banner6 = require("../assets/banner_6.png");
const banner7 = require("../assets/banner_7.png");
const banner8 = require("../assets/banner_8.png");
const banner9 = require("../assets/banner_9.png");
const banner10 = require("../assets/banner_10.png");
const banner11 = require("../assets/banner_11.png");
const bgs = [
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  banner9,
  banner10,
  banner11,
];

const border1 = require("../assets/banner_border_1.png");
const border2 = require("../assets/banner_border_2.png");
const border3 = require("../assets/banner_border_3.png");
const border4 = require("../assets/banner_border_4.png");
const border5 = require("../assets/banner_border_5.png");
const border6 = require("../assets/banner_border_6.png");
const border7 = require("../assets/banner_border_7.png");
const border9 = require("../assets/banner_border_9.png");
const border10 = require("../assets/banner_border_10.png");
const borders = [
  border1,
  border2,
  border3,
  border4,
  border5,
  border6,
  border7,
  border9,
  border10,
];
const black = require("../assets/banner_border_9.png");

function Index() {
  const [image, setImage] = useState(null);
  const [owner, setOwner] = useState("Loading...");
  const [error, setError] = useState(false);
  const [input, setInput] = useState("");
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rand, setRand] = useState(Math.floor(Math.random() * bgs.length));
  const [rand2, setRand2] = useState(
    Math.floor(Math.random() * borders.length)
  );
  const [randomizing, setRandomizing] = useState(false);

  const canvasRef = useRef(null);

  function get_bg_color(img) {
    var canvasImg = document.createElement("canvas");
    canvasImg.width = img.width;
    canvasImg.height = img.height;
    canvasImg.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
    var pixelData = canvasImg.getContext("2d").getImageData(0, 0, 1, 1).data;

    return {
      sum: pixelData[0] + pixelData[1] + pixelData[2],
      bgColor: `rgb(${pixelData[0]},${pixelData[1]},${pixelData[2]})`,
    };
  }

  const updateName = async () => {
    const newName = await fetchEnsName(owner);
    setOwner(newName);
  };

  useEffect(() => {
    if (owner) {
      updateName();
    }
  }, [owner]);

  useEffect(() => {
    if (generate) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.height = 500;
      canvas.width = 1500;

      //BG Image
      let bg = new Image();
      bg.src = bgs[rand];

      bg.onload = () => {
        context.drawImage(
          bg,
          0,
          0,
          canvas.height / (bg.height / bg.width),
          canvas.height
        );

        //Frame Skull
        let border = new Image();
        border.src = borders[rand2];
        border.onload = () => {
          let borderWidth = 400;
          let borderHeight = 400;
          if (border.height > border.width) {
            borderHeight = 400 / (border.width / border.height);
          } else {
            borderWidth = 400 / (border.height / border.width);
          }
          context.drawImage(
            border,
            canvas.width / 2 - borderWidth / 2,
            canvas.height / 2 - borderHeight / 2,
            borderWidth,
            borderHeight
          );
          //Art
          let artImage = new Image();
          artImage.src = image;
          artImage.onload = () => {
            context.drawImage(
              artImage,
              canvas.width / 2 - 275 / 2,
              canvas.height / 2 - 275 / 2,
              275 / (artImage.height / artImage.width),
              275
            );
          };
          //Black
          let textBg = new Image();
          textBg.src = black;
          textBg.onload = () => {
            context.drawImage(textBg, -30, -10, 300, 100);
            context.drawImage(textBg, 1050, -20, 600, 100);

            //Text
            context.fillStyle = "#ff0000";
            context.font = `60px 'TrashHand'`;
            context.fillText(`Skully:`, 20, 55);
            context.fillStyle = "#00d4c8";

            context.fillText(input, 170, 55);
            context.font = `50px 'TrashHand'`;
            context.fillStyle = "#ff0000";

            if (owner.length > 20) {
              var textWidth = context.measureText(
                `${owner?.slice(0, 4)}...${owner?.slice(-4)}`
              ).width;

              context.fillText("OWNER:", 1350 - textWidth, 45);
              context.fillStyle = "#FFFFFF";

              context.fillText(
                `${owner?.slice(0, 4)}...${owner?.slice(-4)}`,
                1480 - textWidth,
                45
              );
            } else {
              var textWidth = context.measureText(owner).width;
              context.fillText("OWNER:", 1350 - textWidth, 45);
              context.fillStyle = "#FFFFFF";

              context.fillText(owner, 1480 - textWidth, 45);
            }
          };
        };
      };
    }
  }, [generate, owner, rand, rand2]);

  const downloadImage = () => {
    const canvas = canvasRef.current;

    var url = canvas.toDataURL("image/png");
    var link = document.createElement("a");
    link.download = "SkulliesWallpaper.png";
    link.href = url;
    link.click();
  };

  const handleSearch = async () => {
    setLoading(true);
    if (!input) {
      return setError("*** Please enter a Token ID ***");
    }
    const options = { method: "GET" };
    await fetch(
      `https://api.opensea.io/api/v1/asset/0x63c9fa23f14c17c5a6f8a0f706bd105bb635e339/${input}`,
      options
    )
      .then((response) => response.json())
      .then(async (response) => {
        await download(response.image_url);
        setError(null);
        setOwner(response.owner.address);
        return await fetchEnsName(response.owner.address);
      })
      .then((name) => setOwner(name))
      .catch((err) => console.error(err));
  };

  const download = (openseaUrl) => {
    fetch(openseaUrl, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          setImage(url);
          setGenerate(true);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reset = () => {
    setImage(null);
    setOwner("Loading...");
    setInput("");
    setGenerate(false);
    setLoading(false);
  };

  useEffect(() => {
    if (randomizing) {
      setTimeout(() => {
        setRand2(Math.floor(Math.random() * borders.length));
        setRand(Math.floor(Math.random() * bgs.length));
      }, 200);
    }
    return () => {
      setTimeout(() => {
        setRandomizing(false);
      }, 600);
    };
  }, [randomizing]);

  return (
    <>
      <Helmet>
        <title>Wallpaper | Skullies GMI</title>
        <meta
          name="description"
          content="Suit up your Twitter with a custom wallpaper of your Skully!"
        />
      </Helmet>
      <div className="bg-black min-h-screen flex flex-col pt-[120px] items-center px-3">
        <div className=" relative w-[300px] sm:w-[450px] lg:w-[600px] xl:w-[750px] mb-6">
          <img
            src={bannerText}
            alt="Twitter Banner"
            className="object-contain"
          />
        </div>
        {!generate ? (
          <form className="w-full max-w-lg bg-zinc-800 rounded-lg py-6 px-3 flex flex-col justify-center shadow-md">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-accentaltdark text-xs font-bold mb-2">
                Skully ID
              </label>
              <input
                className={`appearance-none block w-full bg-gray-200 placeholder-gray-800::placeholder text-gray-700 border ${
                  error && "border-red-500"
                }  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                type="text"
                name="TokenID"
                value={input}
                onChange={(text) => setInput(text.target.value)}
                placeholder="Token ID"
                maxLength={4}
              />
            </div>

            <div>
              <button
                onClick={handleSearch}
                className="font-trash text-[1.2em] tracking-wider bg-accent hover:opacity-50 text-dark py-2 px-4 ml-3 mt-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
              >
                {loading ? "Loading" : "Generate"}
              </button>
            </div>
          </form>
        ) : (
          <div className="flex flex-col justify-center items-center w-full ">
            <div
              className="flex items-center justify-center scale-50 "
              style={{
                fontFamily: "trash",
              }}
            >
              <div className="flex mt-[-200px] p-2">
                <canvas ref={canvasRef} className=""></canvas>
              </div>
            </div>
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded flex items-center mx-auto"
              onClick={() => {
                setRandomizing(true);
              }}
            >
              <FaDice className="mr-2" />
              <span className="font-trash">Randomize</span>
            </button>
            <button
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded flex items-center mx-auto"
              onClick={downloadImage}
            >
              <svg
                className="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
              </svg>
              <span className="font-trash">Download</span>
            </button>
            <p
              className="text-red-600 font-mono underline mt-3 mb-6 cursor-pointer"
              onClick={reset}
            >
              reset
            </p>
          </div>
        )}

        {/* <div className="flex items-center mt-4">
          <p className="text-slate-500">Made with</p>
          <HiHeart className="mx-2 text-red-600" />
          <p className="text-slate-500">
            by{" "}
            <a
              href="https://twitter.com/zmeyer44"
              target="_blank"
              rel="noreferrer"
              className="text-red-600"
            >
              Zachm.eth
            </a>
            <span className="tech text-dark">!</span>
          </p>
        </div> */}

        {/* <div className="flex md:hidden justify-center items-center text-red-600 fixed top-0 bottom-0 left-0 right-0 bg-zinc-900 z-50">
        <h1 className="font-mono text-xl">Must view on Desktop</h1>
      </div> */}
      </div>
      <div
        className="fixed flex top-0 left-0 h-screen w-screen bg-black center z-50 transition-opacity"
        style={{
          pointerEvents: "none",
          opacity: randomizing ? "1" : "0",
        }}
      >
        <img src={dice} className="w-[300px] md:w-[400px]" />
      </div>
    </>
  );
}

export default Index;
