import { NavLink } from "react-router-dom";

const HeaderLink = ({ label, href, hidden = false, large, onClick }) => {
  return (
    <NavLink
      to={href}
      className="text-primary/60 hover:text-accentalt "
      onClick={onClick}
    >
      <div
        className={`${
          hidden && "hidden md:inline-flex"
        } flex items-center cursor-pointer `}
      >
        <h4 className={`text-xl  title ${large && "text-4xl"}`}>{label}</h4>
      </div>
    </NavLink>
  );
};

export default HeaderLink;
